<template>
	<div class="container">
		<div class="content">
			<div class="content_ss" :style="{height:(answerList[current].swiperHeight + 155) + 'px'}">
				<div class="wraps">
					<div class="answer_title">
						<span>{{current+1}}</span>&nbsp;/&nbsp;{{answerNum}}题
					</div>
				</div>
				<el-carousel :interval="5000" :autoplay="false" :initial-index="current" :loop="false" arrow="never"
					class="swiper_box" indicator-position="none" ref="cardShow"
					:style="{height:(answerList[current].swiperHeight + 50) + 'px'}">
					<el-carousel-item v-for="(item,index) in answerList" :key="index" class="swiper_box"
						:style="{height:item.swiperHeight + 'px'}">
						<trueTopic :answer="item" @wd="selectClick" :index="index" :current="current"
							:length="answerList.length" @tab="nextAnswer" ref="answerBox"
							@swiperHeight="getswiperHeight">
						</trueTopic>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>

		<div class="card" style="">
			<button class="subMiter" @click="generatePdf()" style="margin: 0 13px 16px 13px;">
              生成PDF
            </button>
			<div class="time_box">
				<div class="time_box_title">答题时长</div>
				<div class="time_box_time">{{timer}}</div>
			</div>
			<div class="answer_card">
				<div class="time_box_title">答题卡</div>
				<div class="answer_card_content">
					<div class="answer_card_s" @click="cardClick(index)"
						:class="answerList[index].user_res?'yesgreen':''" v-for="(item,index) in answerList"
						:key="index">{{index+1}}</div>
					<div class="answer_card_s" v-for="(item,index) in answerNum - answerList.length"
						:key=" 'aaa'+ index" @click="noanswerClick()">{{answerList.length+index+1}}</div>
					<div class="answer_card_s" style="background: none; border: none;" v-for="index in 5 "
						:key="'aa'+ index"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import trueTopic from '@/components/answer/trueTopic'
	import navBar from '@/components/navBar/navBar'
	import {
		getMockQuesList, //模考
		submitUserAnswerResult, //模考提交
	} from '@/utils/api.js'
	import {
		EventBus
	} from '@/eventBus/eventBus'
	import {makePdf} from '@/utils/erpapi.js'
	export default {
		components: {
			trueTopic,
			navBar
		},
		data() {
			return {
				timer: '00:00:00',
				current: 0,
				correctlv: 99,
				maskShow: false,
				navList: [],
				answer_note: '',
				answerList: [],
				timess: {
					s: '00',
					m: '00',
					h: '00',
					Hour: 0,
					minute: 0,
					second: 0,
				},
				answerType: '',
				answerId: 3,
				take_time: 0,
				dtTimes: '',
				leix: 0,
				swiperHeight: '',
				answerNum: 0,
				limit: 50,
				pages: 1,
				count: 1,
				title: ''
			}
		},
		created() {
			let answerArrs = localStorage.getItem('answerArr')
			if (answerArrs) {
				let ansArr = JSON.parse(answerArrs)
				this.$confirm('检测到上次作答 ' + ansArr.name + ' 未完成, 是否继续完成上次作答?', '提示', {
					confirmButtonText: '完成上次',
					cancelButtonText: '清除上次记录',
					type: 'warning'
				}).then(() => {
					this.$message.success('加载成功')
					this.answerList = ansArr.answerList
					this.timess = ansArr.timess
					this.take_time = ansArr.time
					this.title = ansArr.name
					this.answerId = ansArr.id
					this.answerNum = ansArr.answerNum
					this.pages = ansArr.page
					this.count = ansArr.count
					this.leix = ansArr.leix
					this.answerType = ansArr.type
					let ss = []
					for (let i in this.answerList) {
						if (!this.answerList[i].user_res) {
							ss.push(i)
						}
					}
					this.current = parseInt(ss[0])
					this.getData()
					this.parameter(this.$route.query.parameter, 'jz', ansArr.name)
					console.log()
				}).catch(() => {
					localStorage.removeItem('answerArr')
					this.$message.success('清除成功')
					if (this.$route.query.parameter) {
						this.parameter(this.$route.query.parameter)
					} else {
						this.answerId = this.$route.query.id
					}
					this.getData()
				});
			} else {
				if (this.$route.query.parameter) {
					this.parameter(this.$route.query.parameter)
				} else {
					this.answerId = this.$route.query.id
				}
				this.getData()
			}
		},
		mounted() {
			this.dtTimes = setInterval(() => {
				this.take_time++
				this.timess.s = ++this.timess.second;
				if (parseInt(this.timess.s) < 10) {
					this.timess.s = '0' + this.timess.second
				}
				if (parseInt(this.timess.s) == 60) {
					this.timess.m = ++this.timess.minute
					this.timess.second = 0
					this.timess.s = '0' + this.timess.second
				}
				if (parseInt(this.timess.m) < 10) {
					this.timess.m = '0' + this.timess.minute
				}
				if (parseInt(this.timess.m) == 60) {
					this.timess.h = ++this.timess.Hour
					this.timess.minute = 0
					this.timess.m = '0' + this.timess.minute
					if (parseInt(this.timess.h) < 10) {
						this.timess.h = '0' + this.timess.Hour
					}
				}
				this.timer = this.timess.h + ':' + this.timess.m + ':' + this.timess.s
			}, 1000)
		},
		methods: {
			generatePdf(){
				this.loading = this.$loading({
        lock: true,
        text: "生成中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
          makePdf({
            id:'t'+this.contInfo.id,
            name:this.contInfo.name||'',
            ques_data:this.contInfo.ques,
            score:this.contInfo.score,
			type:this.answerType
          }).then(res=>{
            console.log(res)
			this.loading.close();
            window.open(res.data)
          })
        },
			async getData() {
				if (this.answerType == 'mock') {
					console.log(1)
					if (this.pages <= this.count) {
						console.log(2)
						await getMockQuesList({
							mock_id: this.answerId,
							page: this.pages,
							limit: this.limit
						}).then(res => {
							if (res == undefined) {
								this.$router.go('-1');
							} else {

								this.answerNum = res.total
								this.answerList = this.answerList.concat(res.list)
								this.count = Math.ceil(res.total / this.limit)
								
								let arr = []
								for (let i of this.answerList) {
									let str = i.genre.toString()
									i.genreType = str.substr(str.length - 1, 1)
									if (i.genreType == '6') {
										i.titles = []
										arr = i.title.split('____')
										i.titles = arr
									}
								}
								this.pages++
								setTimeout(() => {
									this.getData()
								}, 500)
							}

						}).catch(err => {
							console.log(err)
							this.$router.go(-1)
						})
					}

				} else if (this.answerType == 'zt') {
					if (this.pages <= this.count) {
						await getLinianInfo({
							id: this.answerId,
							page: this.pages,
							limit: this.limit
						}).then(res => {
							this.answerList = this.answerList.concat(res.data.data)
							this.answerNum = res.data.count
							this.count = Math.ceil(res.data.count / this.limit)
							
							let arr = []
							for (let i of this.answerList) {
								let str = i.genre.toString()
								i.genreType = str.substr(str.length - 1, 1)
								if (i.genreType == '6') {
									i.titles = []
									arr = i.title.split('____')
									i.titles = arr
								}
							}
							this.pages++
							setTimeout(() => {
								this.getData()
							}, 500)
						}).catch(err => {
							this.$router.go(-1)
						})
					}
				} else {
					if (this.pages <= this.count) {
						await getTestPaperQuesList({
							ques_id: this.answerId,
							page: this.pages,
							limit: this.limit
						}).then(res => {

							this.answerList = this.answerList.concat(res.data)
							this.answerNum = res.total
							this.count = Math.ceil(res.total / this.limit)
							let arr = []
							for (let i of this.answerList) {
								let str = i.genre.toString()
								i.genreType = str.substr(str.length - 1, 1)
								if (i.genreType == '6') {
									i.titles = []
									arr = i.title.split('____')
									i.titles = arr
								}
							}
							this.pages++
							setTimeout(() => {
								this.getData()
							}, 500)
						}).catch(err => {
							console.log(err)
							this.$router.go(-1)
						})
					}
				}

			},
			noanswerClick() {
				this.$message.warning('试题尚未加载完成，请稍后再试')
			},
			parameter(arr, fun, name) {

				let opdata
				if (arr) {
					opdata = JSON.parse(decodeURIComponent(arr))
				} else {
					opdata = JSON.parse(localStorage.getItem('answerNav'))
				}
				localStorage.setItem('answerNav', JSON.stringify(opdata))
				if (fun !== 'jz') {
					this.title = opdata.title
					this.answerId = opdata.id
					this.answerType = opdata.type
				}
				this.navList = opdata.arr
				if (name) {
					this.navList.push({
						name: name,
						path: "/trueTopic",
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0,
						type: 'answers'
					})
				} else {
					this.navList.push({
						name: this.title,
						path: "/trueTopic",
						studyCurrent: 0,
						tabbarChildCurrent: 0,
						tabbarCurrent: 0,
						type: 'answers'
					})
				}
				EventBus.$emit('navList', this.navList)
			},
			stopClick(text) {
				localStorage.removeItem('times')
			},
			dtCardClick() {
				this.maskShow = true
				// 计算正确率
				var yes = this.answerList.filter(function(item) { //查找回答正确的数组并组成新数组
					return item.correct == 'yes';
				})
				console.log(yes.length / this.answerList.length * 100)
				this.correctlv = (yes.length / this.answerList.length * 100).toFixed(2)
			},
			cardClick(i) {
				let that = this
				this.current = i
				this.$refs.cardShow.setActiveItem(i)
				this.maskShow = false
				// if (i == (parseInt((this.pages - 2).toString() + 7)) || i > (parseInt((this.pages - 2).toString() + 7))) {
				// 	that.getData()

				// }
			},
			selectClick(e) {
				console.log('答题数据', e)
				// if (e.index == (parseInt((this.pages - 2).toString() + 7)) || e.index > (parseInt((this.pages - 2)
				// 		.toString() + 7))) {
				// 	this.getData()
				// }
				this.answerList[e.index].correct = e.correct
				this.answerList[e.index].ansTimer = e.ansTimer
				if (e.correct == 'yes') {
					if (this.answerType == 'mock') {
						this.answerList[e.index].is_right = 1
					} else {
						this.answerList[e.index].is_right = 1
					}
				} else {
					if (this.answerType == 'mock') {
						this.answerList[e.index].is_right = 2
					} else {
						this.answerList[e.index].is_right = 2
					}

				}
				this.answerList[e.index].user_res = e.user_res

				console.log(this.answerList[e.index])
				var yesArr = this.answerList.filter(function(item) { //查找已经作答的数组
					return item.correct;
				})
				let mz = this.answerList.filter(item => {
					return !item.correct
				})
				if (this.answerNum === yesArr.length) { //判断是否答完题如果答完跳转到结束页面
					clearInterval(this.dtTimes)
					var yes = this.answerList.filter(function(item) { //查找回答正确的数组并组成新数组
						return item.correct == 'yes';
					})
					let error_num = this.answerList.filter(function(item) { //查找回答正确的数组并组成新数组
						return item.correct == 'no';
					})
					this.correctlv = ((yes.length / this.answerList.length) * 100).toFixed(2) //计算正确率
					if (this.answerType == 'mock') {
						submitUserAnswerResult({
							mock_id: this.answerId,
							exam_num: this.answerList.length,
							score: this.answerList.length - error_num.length,
							spent_time: this.take_time,
							content: this.answerList,
						}).then(res => {

						})
					} else if (this.answerType == 'sz') {
						currentAffairsPost({
							ques_id: this.answerId,
							score: this.answerList.length - error_num.length,
							content: this.answerList,
							exam_num: this.answerList.length,
							spent_time: this.take_time,
						}).then(res => {

						})
					} else if (this.answerType == 'zt') {
						submitLinianWrong({
							id: this.answerId,
							exam_num: this.answerList.length,
							score: this.answerList.length - error_num.length,
							spent_time: this.take_time,
							content: this.answerList,
						}).then(res => {

						})
					} else {
						userAnswerResult({
							ques_id: this.answerId,
							exam_num: this.answerList.length,
							score: this.answerList.length - error_num.length,
							spent_time: this.take_time,
							content: this.answerList,
						}).then(res => {

						})
					}
					let arrs = {
						List: this.answerList,
						time: this.timer,
						correctlv: this.correctlv
					}
					localStorage.setItem('answer', JSON.stringify(arrs))
					let navarrs = {
						arr: this.navList,
						title: '答题结算',
						id: this.answerId,
						type: 'zt',
						paths:this.$route.fullPath
					}

					localStorage.removeItem('answerArr')
					this.$router.push('/AnswerEnd?parameter=' + encodeURIComponent(encodeURIComponent(JSON
						.stringify(navarrs))));
					return
				} else if (e.index + 1 == this.answerList.length) {
					this.$alert('你还有' + mz.length + '道题还没做，请点击答题卡查看', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.maskShow = true
						}
					});
				} else {
					let answerArr = {
						name: this.title,
						answerList: this.answerList,
						time: this.take_time,
						timess: this.timess,
						id: this.answerId,
						answerNum: this.answerNum,
						page: this.pages,
						count: this.count,
						type: this.answerType,
						leix: this.leix
					}
					localStorage.setItem('answerArr', JSON.stringify(answerArr))
				}
				if (e.next == true) {
					this.current = e.index + 1
					this.$refs.cardShow.next()

				}
			},
			nextAnswer(e) { //切换题目
				if (e.next == true) {
					this.$refs.cardShow.next()
					this.current = e.index + 1
				} else {
					this.$refs.cardShow.prev()
					this.current = e.index - 1
				}
				// if (e.index == (parseInt((this.pages - 2).toString() + 7)) || e.index > (parseInt((this.pages - 2)
				// 		.toString() + 7))) {
				// 	this.getData()
				// }
			},
			getswiperHeight(e) {
				// this.swiperHeight = 
				this.answerList[e.index].swiperHeight = e.height + 28
			}
		},
		beforeDestroy() {
			clearInterval(this.dtTimes)
		}
	}
</script>
<style type="text/css">
	.el-carousel__item {
		overflow: visible !important;
	}
</style>
<style scoped="scoped">
	/* @import url("../../../assets/css/answer.css"); */
	.notess {
		background: #F7F7F7;
		padding-top: 40px;
	}

	.card {
		width: 350px;
	}

	.note_box {
		padding: 30px 20px;
		margin-top: 40px;
		background: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
	}

	.note_title {
		font-size: 18px;
		font-weight: bold;
		padding-left: 15px;
		border-left: 5px solid #47BE5E;
	}

	.note {
		padding: 30px 20px;
		position: relative;
	}

	.note span {
		position: absolute;
		top: 160px;
		right: 40px;
		color: #666;
	}

	.note textarea {
		height: 150px;
		width: 100%;
		resize: none;
		border: none;
		background: #F7F7F7;
		padding: 15px;
	}

	.note div {
		width: 80px;
		height: 36px;
		background: #47BE5E;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 16px;
		margin-top: 15px;
	}

	.note_list {
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
	}

	.note_pic {
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}

	.note_pic img {
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}

	.note_con {
		flex: 1;
		padding-left: 20px;
	}

	.note_info {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.note_time {
		font-size: 14px;
		color: #666;
		margin-left: 15px;
	}

	.user_name {
		font-size: 16px;
		color: #666;
	}

	.note_text {
		margin-top: 27px;
		font-size: 16px;
	}

	.note_btns div {
		width: 46px;
		height: 24px;
		background: #EEEEEE;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		color: #999
	}

	.lines,
	.note_btns {
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}

	.content {
		width: 1132px;
		background: #fff;
		min-height: 670px;
	}

	.content_ss {
		background: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
		padding: 20px;
		min-height: 670px;
		background: url(../../../assets/answer_BG.png) #fff;
	}

	.card_title {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #fff;
		font-weight: bold;
	}

	.correctlv {
		font-size: 16px;
		color: #fff;
		margin-top: 10px
	}

	.yesred {
		color: #FF684B !important;
		border: 1px solid #FFBAAD !important;
		background: #FFF0ED !important;
	}

	.yesgreen {
		/* background: #02D19D !important; */
		color: #47BE5E !important;
		border: 1px solid #7CEA91 !important;
		background: #E5FFEC !important;
	}

	.number_box {
		position: absolute;
		top: 168px;
		left: 40px;
		width: 415px;
		height: 320px;
		overflow-y: scroll;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.number_box::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 4px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 4px;
		scrollbar-arrow-color: red;

	}

	.number_box::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 5px;
		/* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
		background: #fff;
		scrollbar-arrow-color: red;
	}

	.number_box::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		/* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
		border-radius: 0;
		background: #fff;
	}

	.number_box div {
		width: 32px;
		margin: 5px 3px;
	}

	.numbers {
		width: 32px;
		height: 32px;
		border-radius: 4px;
		background: #F7F7F9;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 5px 3px;
	}

	.submit_btn {
		position: absolute;
		bottom: 36.8px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 495px;
		padding: 0 130px;
	}

	.submit_btn div {
		width: 86px;
		height: 34px;
		background: #47BE5E;
		border-radius: 10px;
		text-align: center;
		line-height: 35.5px;
		color: #fff;
	}

	.submit_btn .jixu {
		background: #FA4E53;
	}

	.container {
		display: flex;
		justify-content: space-between;
		width: 1500px;
	}

	.time_box {
		height: 135px;
		background: #fff;
		padding: 15px 0;
	}

	.time_box_title {
		border-left: 5px solid #47BE5E;
		font-size: 18px;
		font-weight: bold;
		padding-left: 25px;
	}

	.time_box_time {
		font-size: 36px;
		color: #666;
		height: 117px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	.answer_card {
		margin-top: 20px;
		background: #fff;
	}

	.answer_card {
		padding: 15px 0;
	}

	.answer_card_content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		padding: 0 10px;
		flex-direction: row;
		flex-wrap: wrap;
		max-height: 696px;
		overflow-y: scroll;
	}

	.answer_card_content .answer_card_s {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 30px;
		height: 30px;
		background: #F7F7F7;
		border: 1px solid #DADADB;
		color: #666;
		border-radius: 2px;
		margin: 7px;
	}

	.answer_card_content div {
		height: 30px;
	}

	.pages_box {
		position: absolute;
		bottom: 20px;
		right: 42px;
	}


	.answer_title {
		height: 20px;
		font-size: 16px;
		color: #666;
	}

	.answer_title span:first-child {
		font-size: 26px;
		color: #47BE5E;
		font-weight: bold;
	}

	.answer_title .big {
		height: 20px;
		background: linear-gradient(90deg, #505DDF 0%, #64B0FE 100%);
		border-radius: 0px 10px 10px 0px;
		display: flex;
		align-items: center;
		color: #fff;
		font-size: 20px;
		padding: 0 27px;
		padding-right: 48px;
		position: absolute;
		left: -27px;
		z-index: 3;
		top: 0;
	}

	.answer_title .big img {
		position: absolute;
		width: 34px;
		right: 0;
		top: 12px;
		height: 17px;
	}

	.answer_title .small {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.small div {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 18px;
	}

	.small div img {
		margin-right: 9px;
		width: 18px;
		height: 18px;
	}

	.small .times {
		color: #666666;
	}

	.small .card_btn {
		margin-left: 28px;
		color: #FA4E53;
	}

	.swiper_box {
		min-height: 680px;
	}
</style>
