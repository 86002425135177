<template>
  <div class="usercon">
    <div class="usercon_left">
      <div class="left_i" v-if="sourceva != 0">
        <div class="question_set">最近学习</div>
        <div class="setli" v-if="section.section_info && sourceva == 1">
          <div class="set_name">{{ section.section_info.checkpoint_info.training_info.training_name }}</div>
          <div class="set_icon">></div>
          <div class="set_name">{{ section.section_info.checkpoint_info.checkpoint_name }}</div>
          <div class="set_icon">></div>
          <div class="set_name">{{ section.section_info.section_name }}</div>
        </div>
        <div v-if="sourceva == 2" class="setli">
          <div class="set_name">{{ section.subject_content.subject_info.subject_name }}</div>
        </div>
        <div class="course_list">
          <div>
            <div class="course_i" v-for="(item, index2) in training" :key="index2" @click="tolChange(item, item.id)">
              <div class="cour_img" :style="{ background: prbaColor(item.type) }">
                <img v-if="item.type == 1" src="../../assets/images/login/icon_tj_wk.png" alt="">
                <img v-if="item.type == 2 || item.type == 4" src="../../assets/images/login/icon_tj_zl.png" alt="">
                <img v-if="item.type == 3" src="../../assets/images/login/icon_tj_cy.png" alt="">
                <div v-if="item.type == 1">网课</div>
                <div v-if="item.type == 2">测验</div>
                <div v-if="item.type == 3">资料</div>
                <div v-if="item.type == 4">练习</div>
              </div>
              <div class="cour_info">
                <div class="cour_details">
                  <div class="cour_name">{{ item.content_name || item.name }}</div>
                  <div class="cour_kankj">
                    <div v-if="item.type == 1" style="width:180px">课程时长：<span
                        :style="{ color: backgChange(item.type) }">{{ item.count_times }}</span> 分钟</div>
                    <div v-if="item.type == 2 || item.type == 4" style="width:180px">总分：<span
                        :style="{ color: backgChange(item.type) }">{{ item.score || 0 }}</span> 分</div>
                    <div v-if="item.type == 3" style="width:180px"><img src="../../assets/images/login/icon_zt_zip.png"
                        alt="" style="margin-right:8px">PDF电子资料</div>
                    <div v-if="item.type == 3" style="width:180px">共<span :style="{ color: backgChange(item.type) }">1</span>
                      份</div>
                    <div class="cour_progress" v-if="item.type != 3 && sourceva == 2">
                      <div class="courpro_lg" :style="{ background: backgChange(item.type), width: (item.progress || 0) + '%' }">
                      </div>
                      <div class="cour_gard">
                        <div v-for="(item, index) in 4" :key="index"></div>
                      </div>
                    </div>
                    <div v-if="item.type != 3 && sourceva == 2">
                      <div v-if="item.type == 1">已观看：<span
                          :style="{ color: backgChange(item.type) }">{{ item.progress || 0 }}</span> %</div>
                      <div v-else>已完成：<span :style="{ color: backgChange(item.type) }">{{ item.progress || 0 }}</span> %</div>
                    </div>
                    <div class="cour_progress" v-if="item.type != 3 && sourceva == 1">
                      <div class="courpro_lg"
                        :style="{ background: backgChange(item.type), width: (item.completion || 0) + '%' }"></div>
                      <div class="cour_gard">
                        <div v-for="(item, index) in 4" :key="index"></div>
                      </div>
                    </div>
                    <div v-if="item.type != 3 && sourceva == 1">
                      <div v-if="item.type == 1">已完成：<span
                          :style="{ color: backgChange(item.type) }">{{ item.progress || 0 }}</span> %</div>
                      <div v-else>得分：<span :style="{ color: backgChange(item.type) }">{{ item.my_score || 0 }}</span> 分</div>
                    </div>
                  </div>
                </div>
                <div v-if="sourceva == 2 && section.is_expire == 1">
                  <div class="cour_btn" :style="{ background: backgChange(item.type), color: '#ffffff' }"
                    v-if="item.progress < 100">
                    {{ item.type == 3 ? '去下载' : '去学习' }}
                  </div>
                  <div v-if="item.progress == 100 && item.type != 3" class="cour_btn">已完成</div>
                </div>
                <div v-if="sourceva == 1 && section.is_expire == 1">
                  <div class="cour_btn" :style="{ background: backgChange(item.type), color: '#ffffff' }"
                    v-if="!item.completion">
                    {{ item.type == 3 ? '去下载' : '去学习' }}
                  </div>
                  <div v-if="item.completion > 0 && item.type != 3" class="cour_btn">已完成</div>
                </div>
                <div v-if="section.is_expire == 0" class="cour_btn guqisty">已过期</div>
                <!-- <div v-if="item.progress==100&&item.type==3" :style="{background:backgChange(item.type),color:'#ffffff'}" class="cour_btn" @click="goSxxi(item,item.id)">
                       去下载
                  </div> -->
              </div>
            </div>
          </div>
          <div class="tpelist" v-if="false">
            <div class="tpelist_i" v-for="(tiy, index3) in training" :key="index3" @click="tolChange(tiy, tiy.id)">
              <div class="tpelist_i_left">
                <div class="tpe_inx" :style="{ color: backgChange(tiy.type) }">{{ index3 + 1 }}</div>
                <div class="tpe_type" :style="{ color: backgChange(tiy.type) }">
                  <img v-if="tiy.type == 1" src="../../assets/images/login/icon_tk_b.png" alt="" />
                  <img v-if="tiy.type == 2 || tiy.type == 4" src="../../assets/images/login/icon_tx_cy.png" alt="" />
                  <img v-if="tiy.type == 3" src="../../assets/images/login/icon_tx_zl.png" alt="" />
                  <div v-if="tiy.type == 1">网课</div>
                  <div v-if="tiy.type == 2">测验</div>
                  <div v-if="tiy.type == 3">资料</div>
                  <div v-if="tiy.type == 4">练习</div>
                </div>
                <div class="tpe_name" style="width:244px">{{ tiy.content_name || tiy.name }}</div>
              </div>
              <div class="tpelist_i_right" v-if="section.is_expire == 1">
                <div class="cour_progress" v-if="tiy.type != 3">
                  <div class="courpro_lg" :style="{ background: backgChange(tiy.type), width: tiy.progress + '%' }"></div>
                  <div class="cour_gard">
                    <div v-for="(item, index5) in 4" :key="index5"></div>
                  </div>
                </div>
                <div class="tpe_pro" v-if="tiy.type == 1">已观看：<span
                    :style="{ color: backgChange(tiy.type) }">{{ tiy.progress || 0 }}</span> %</div>
                <div class="tpe_pro" v-else>已完成：<span :style="{ color: backgChange(tiy.type) }">{{ tiy.my_score || 0 }}</span>
                  分</div>
                <!-- <div class="cour_btn" :style="{background:backgChange(tiy.type),color:'#ffffff'}" v-if="!item.completion">
                      {{tiy.type==3?'去下载':'去学习'}}
                    </div> -->
                <div v-if="sourceva == 2">
                  <div class="cour_btn" :style="{ background: backgChange(tiy.type), color: '#ffffff' }"
                    v-if="tiy.progress < 100">
                    {{ tiy.type == 3 ? '去下载' : '去学习' }}
                  </div>
                  <div v-if="tiy.progress == 100 && tiy.type != 3" class="cour_btn">已完成</div>
                </div>
                <div v-if="sourceva == 1">
                  <div class="cour_btn" :style="{ background: backgChange(tiy.type), color: '#ffffff' }"
                    v-if="!tiy.completion">
                    {{ tiy.type == 3 ? '去下载' : '去学习' }}
                  </div>
                  <div v-if="tiy.completion > 0 && tiy.type != 3" class="cour_btn">已完成</div>
                </div>
                <div v-if="tiy.progress == 100 && tiy.type != 3" class="cour_btn">已完成</div>
                <div v-if="tiy.progress == 100 && tiy.type == 3" :style="{ background: backgChange(tiy.type), color: '#ffffff' }"
                  class="cour_btn">
                  去下载
                </div>
              </div>
              <div class="tpelist_i_right" v-else>
                <div class="cour_btn guqisty">已过期</div>
              </div>
            </div>
          </div>
        </div>
        <div class="question_set" style="margin-top:40px" v-if="sourceva == 1">完成条件</div>
        <div class="courju_i"
          @click="goStyindex(section.section_id, item, section.section_info.checkpoint_info.training_id)"
          v-for="(item, index3) in condition" :key="index3">
          <div class="courju_left">
            <div class="courju_index"
              :style="{ background: item.is_complete == 0 ? '' : '#308DFF', color: item.is_complete == 0 ? '' : '#ffffff' }">{{ index3
              <9?0:''}}{{ condition.length > 10 ? index3 + 1 : index3 + 1 }}</div>
            <div class="courju_name">{{ item.title }}</div>
          </div>
          <div class="courju_status" :style="{ color: item.is_complete == 0 ? '#308DFF' : '' }">
            {{ item.is_complete == 0 ? item.condition_type == 1 ? '去完成' : '待审查' : '已完成' }}
            <img v-if="item.is_complete == 0" src="../../assets/images/login/icon_tj_no.png" alt="">
            <img v-else src="../../assets/images/login/icon_tj_ok.png" alt="">
          </div>
        </div>

      </div>
      <div class="left_i">
        <div class="question_set" style="margin-bottom:20px;">特训</div>
        <div class="course_list" :style="{ height: kanzkShow ? stutraData.length < 3 ? 'auto' : '380px' : 'auto' }">
          <div class="course_i" v-for="(item, index2) in stutraData" :key="index2" style="width:100%;">
            <div class="cour_info" style="width:100%;">
              <div class="cour_details">
                <div class="cour_name">{{ item.training_name }}</div>
                <div class="cour_kankj">
                  <!-- <div style="width:180px">课程时长：<span>{{item.training_statistics.count_time||0}}</span> 分钟</div> -->
                  <div class="cour_progress" style="margin-left:0">
                    <div class="courpro_lg" :style="{ width: item.training_statistics.total_progress || 0 + '%' }"></div>
                    <div class="cour_gard">
                      <div v-for="(item, index) in 4" :key="index"></div>
                    </div>
                  </div>
                  <div>已完成：<span>{{ item.training_statistics.total_progress || 0 }}</span> %</div>
                </div>
              </div>
              <div @click="expTmie()" v-if="item.is_expire == 0" class="cour_btn guqisty">已过期</div>
              <div v-else>
                <div class="cour_btn" v-if="item.training_statistics.total_progress == 100" @click="goStyindex(0, item)">
                  已完成</div>
                <div class="cour_btn" style="background:#308DFF;color:#ffffff;" v-else
                  @click="goStyindex(0, item, item.training_id)">继续学习</div>
              </div>
            </div>
          </div>
        </div>
        <div class="more_n" @click="kanzkShow = !kanzkShow">{{ kanzkShow ? '展开更多' : '收起' }}<img
            src="../../assets/images/login/icon_tx_more.png" alt=""></div>
      </div>
      <div class="left_i">
        <div class="quenflex">
          <div class="question_set" style="margin-bottom:20px;">专题</div>
          <div class="quenflex_input">
            <img src="../../assets/search.png" alt="">
            <input @keyup.enter="blurSeacrh" v-model="inputject" type="text" placeholder="输入专题关键词">
          </div>
        </div>
        <el-collapse accordion>
          <div class="elcoll" v-for="(subj, index4) in subjData" :key="index4">
            <el-collapse-item>
              <template slot="title">
                <div class="collapse_title">
                  <div class="coll_name">{{ subj.catalogue_name }}</div>
                  <div class="coll_dec">已添加<span>{{ subj.count }}</span>个专题</div>
                </div>
              </template>
              <div class="coll_list">
                <div class="coll_i" v-for="(jd, index5) in subj.subject" :key="index5" @click="goTopicinfo(jd, subj)">
                  <div class="coll_name">{{ jd.subject_name }}</div>
                  <div class="coll_dec">已添加<span>{{ jd.content_nums }}</span>个专题</div>
                  <div class="coll_btn">{{ subj.is_expire == 1 ? '查看详情' : '已过期' }}</div>
                </div>
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>
    </div>
    <div class="usercon_right">
      <div class="right_i" v-if="planData.length > 0">
        <div class="question_set" style="margin-bottom:20px;">学习计划</div>
        <div class="plan_i" v-for="(item, index6) in planData" :key="index6" v-if="jihagShow ? index6 < 2 : true">
          <div class="plan_name">{{ item.title }}</div>
          <div class="plan_is">
            <div class="plan_time"><img src="../../assets/yongshixs.png" alt="">{{ item.plan_date }}</div>
            <div class="plan_status">
              <img v-if="item.status == 1" src="../../assets/images/login/icon_tj_no.png" alt="">
              <img v-else src="../../assets/images/login/icon_tj_ok.png" alt="">
              {{ item.status == 1 ? '未完成' : item.status == 2 ? '已完成' : '已超时' }}
            </div>
          </div>
          <div class="plan_bottom">
            <div class="plan_dec" @click="releChange(item.section_info, item.is_status)">
              相关内容{{ item.is_status == 2 ? 1 : 0 }}/{{ item.section_info.length }}</div>
            <div class="plan_btn" @click="summarize(item)">总结</div>
          </div>
        </div>
        <div class="ributton" v-if="planData.length > 0" @click="gogendRi">查看更多 →</div>
      </div>
      <div class="right_i">
        <div class="question_set" style="margin-bottom:20px;">题本</div>
        <div class="quenflex_input" style="margin:0 auto 30px auto" @click="inputClick">
          <img src="../../assets/search.png" alt="">
          <!-- <input @click="inputClick" type="text" disabled placeholder="搜题"> -->
          <div style="color:#999">搜题</div>
        </div>
        <div class="question_i" @click="goEth('third')">
          <div class="question_left">
            <div><img src="../../assets/images/login/icon_tb_a.png" alt=""></div>
            错题
          </div>
          <div class="question_num">{{ ctatiInfo.error_num }}</div>
        </div>
        <div class="question_i">
          <div class="question_left">
            <div><img src="../../assets/images/login/icon_tb_b.png" alt=""></div>
            笔记
          </div>
          <div class="question_num" style="color:#308DFF;">{{ ctatiInfo.note_count }}</div>
        </div>
        <div class="question_i" @click="goEth('first')">
          <div class="question_left">
            <div><img src="../../assets/images/login/icon_tb_c.png" alt=""></div>
            收藏
          </div>
          <div class="question_num" style="color:#F7A243;">{{ ctatiInfo.collect_count }}</div>
        </div>
        <div class="question_i" @click="goEth('second')">
          <div class="question_left">
            <div><img style="width:22px;height:22px" src="../../assets/images/login/woelss.png" alt=""></div>
            资料
          </div>
          <div class="question_num" style="color:#ff3069;">{{ ctatiInfo.Information_num }}</div>
        </div>
      </div>
    </div>
    <!-- 总结 -->
    <el-dialog title="自我总结" :visible.sync="centerDialogVisible" width="30%" top="15%" center>
      <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitInput">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 相关内容 -->
    <el-dialog title="计划学习内容" :visible.sync="relevantShow" width="30%" top="15%" center>
      <div class="colc_list">
        <div class="colc_i" v-for="(ite, index9) in releInfo" :key="index9"
          @click="goStyindex(ite.id, ite.checkpoint_info.training_info, ite.checkpoint_info.training_id)">
          <img v-if="ite.is_status == 1" src="../../assets/images/login/icon_tj_ok.png" alt="">
          <img v-else src="../../assets/images/login/icon_tj_no.png" alt="">
          <div class="colc_i_name" v-if="ite.checkpoint_info">
            {{ ite.checkpoint_info.training_info.training_name }}-{{ ite.checkpoint_info.checkpoint_name }}-{{ ite.section_name }}
          </div>
          <div class="colc_i_pos">特训{{ relrStatus }}</div>
        </div>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog title="计划学习内容" :visible.sync="detectionShow" width="30%" top="15%" center>
      <div>
        <el-form ref="form" :model="teform" label-width="80px">
        <el-form-item label="活动区域">
          <el-select v-model="teform.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动区域">
          <el-select v-model="teform.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserInfo,
  getStuTrainingLog,
  getStuTraining,
  getSubject,
  getPlan,
  getSubjectStatistics,
  updatePlan,
  getWrongQuestionRecall
} from "@/utils/erpapi.js";
export default {
  data() {
    return {
      teform:{},
      detectionShow: false,
      centerDialogVisible: false,
      relevantShow: false,//相关内容
      textarea: '',
      condition: [],
      section: {},
      training: [],
      stutraData: [],//特训
      subjData: [],//专题
      planData: [],//学习计划
      kanzkShow: true,
      jihagShow: true,
      inputject: "",
      releInfo: {},//计划与学习内容
      relrStatus: "",
      ctatiInfo: {},//题本统计
      summarizeInfo: {},
      loading: "",
      sourceva: 1,
    };
  },
  components: {
  },
  destroyed() {
    this.loading.close();
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: "",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    this.getStuTrainingLog()
    this.getStuTraining()
    this.getSubject()
    this.getPlan()
    this.getSubjectStatistics()
  },
  methods: {
    goEth(name) {
      this.$router.push("/thatperson?name=" + name);
    },
    // 题本统计
    getSubjectStatistics() {
      getSubjectStatistics().then(res => {
        this.ctatiInfo = res.data
      })
    },
    //
    releChange(data, status) {
      console.log(status)
      if (data.length > 0) {
        this.relrStatus = data.status
        this.relevantShow = true
        console.log(data)
        this.releInfo = data
      }

    },
    gogendRi() {
      this.$router.push('studyplan')
    },
    // 提交总结
    submitInput() {
      updatePlan({
        id: this.summarizeInfo.id,
        section_id: "",
        plan_id: "",
        status: this.summarizeInfo.status,
        remark: this.textarea,
      }).then(res => {
        this.centerDialogVisible = false
        this.getPlan()
      })
    },
    // 总结
    summarize(data) {
      this.centerDialogVisible = true
      this.textarea = data.remark
      this.summarizeInfo = data
    },
    // 学习计划
    getPlan() {
      getPlan({
        type: 1
      }).then(res => {
        this.planData = res.data
      })
    },
    expTmie() {
      this.$message.info({
        message: "已过期",
        showClose: true,
      });
    },
    // 专题详情
    goTopicinfo(data, onsub) {
      if (onsub.is_expire == 0) {
        this.$message.info({
          message: "已过期",
          showClose: true,
        });
        return
      }
      this.$router.push('/topicinfo?id=' + data.id)
    },
    // 题搜索
    inputClick() {
      this.$router.push('/courseSearch')
    },
    // 专题搜索
    blurSeacrh() {
      this.getSubject()
    },
    // 专题
    getSubject() {
      getSubject({
        name: this.inputject,
      }).then(res => {
        this.subjData = res.data
      })
    },
    secExchangeMoment(value) {
      console.log(value)
      let secondTime = parseInt(value);// 秒
      let minuteTime = 0;// 分
      let hourTime = 0;// 小时
      if (secondTime > 59) {// 如果秒数大于59，将秒数转换成整数
        // 获取分钟，除以59取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 59) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      return [hourTime, minuteTime, secondTime];
    },
    getStuTrainingLog() {
      this.loading.close();
      getStuTrainingLog().then(res => {
        this.sourceva = res.data.source
        this.loading.close();
        this.condition = res.data.condition
        this.section = res.data.section
        console.log(this.section.section_info)
        this.training = res.data.training || res.data.subject
      })
    },
    getStuTraining() {
      getStuTraining().then(res => {
        this.stutraData = res.data
        console.log(this.stutraData)
      })
    },
    goAns(id, data) {
      if (data.type == 1) {
        this.$router.push("/courseVideo?id=" + id + "&source=" + this.sourceva);
        return
      }
      let navarrs = {
        content_id: id,
        result_id: id,
        training_log_id: data.training_log_id,
        paths: this.$route.fullPath
      }
      this.$router.push('/AnswerEnd?parameter=' + encodeURIComponent(encodeURIComponent(JSON.stringify(
        navarrs))) + "&subjectType=" + this.sourceva + "&source=" + this.sourceva);
    },
    // 
    tolChange(data, id) {
      if (this.section.is_expire == 0) {
        this.$message.info({
          message: "已过期",
          showClose: true,
        });
        return
      }
      if (!data.completion) {
        this.goSxxi(data, id)
        return
      }
      if (data.completion > 0 && data.type == 3) {
        this.goSxxi(data, id)
      }
      if (data.completion > 0 && data.type != 3) {
        this.goAns(id, data)
      }
    },
    // 去学习
    goSxxi(data, id) {
      this.subjectType = data.type
      if (data.type == 1) {
        this.$router.push("/courseVideo?id=" + id + "&source=" + this.sourceva);
      } else if (data.type == 3) {
        window.open(data.url || data.file_url);
        this.addsTrainin(id)
      } else {
        if (this.sourceva == 1) {
          this.$router.push("/answer?id=" + id + "&type=" + data.type);
        } else {
          this.$router.push("/cetopanswer?id=" + id + "&type=" + data.type);
        }

      }
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.cour_progress {
  position: relative;
  width: 100px;
  height: 10px;
  margin-left: 35px;
  margin-right: 15px;
  background: #D6E8FF;
  border-radius: 2px;

  .courpro_lg {
    position: absolute;
    height: 10px;
    z-index: 90;
    width: 50%;
    background: #308DFF;
    top: 0;
    left: 0;
    border-radius: 2px;
  }

  .cour_gard {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;

    div {
      width: 0px;
      height: 10px;
      background: #e4050500;
      border-right: 4px solid #ffffff;
      margin: 0;
    }
  }
}

.cour_btn {
  width: 100px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 16px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 32px;
  margin-left: 30px;
}

div {
  cursor: pointer;
}

.usercon {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.question_set {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393F47;
}

.left_i {
  width: 870px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px #E8EDF3;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  box-sizing: border-box;

  .setli {
    display: flex;
    align-items: center;
    margin: 30px 0;

    .set_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      letter-spacing: 1px;
    }
  }
}

.course_list {
  overflow: hidden;

  .course_i {
    width: 810px;
    height: 110px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .cour_info {
      width: 690px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cour_btn {
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #EBEBEB;
        border-radius: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 32px;
      }

      .cour_details {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cour_name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #393F47;
        }

        .cour_kankj {
          display: flex;
          align-items: center;

          .cour_progress {
            position: relative;
            width: 100px;
            height: 10px;
            margin-left: 0;
            margin-right: 15px;
            background: #D6E8FF;
            border-radius: 2px;

            .courpro_lg {
              position: absolute;
              height: 10px;
              z-index: 90;
              width: 50%;
              background: #308DFF;
              top: 0;
              left: 0;
              border-radius: 2px;
            }

            .cour_gard {
              position: absolute;
              z-index: 99;
              width: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;

              div {
                width: 21px;
                height: 10px;
                background: #e4050500;
                border-right: 4px solid #ffffff;
                margin: 0;
              }
            }
          }

          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 8px;

            span {
              font-size: 18px;
              color: #308DFF;
              font-weight: bold;
            }
          }
        }
      }
    }

    .cour_img {
      width: 120px;
      height: 110px;
      background: linear-gradient(90deg, #0FA2FF, #17BDFF);
      border-radius: 6px 0px 0px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }

      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 10px;
      }
    }
  }
}

.courju_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  .courju_left {
    display: flex;
    align-items: center;

    .courju_index {
      width: 40px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #f6f6f6;
      border-radius: 12px;
      margin-right: 20px;
      font-size: 18px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #999999;
    }

    .courju_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  .courju_status {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #308DFF;

    img {
      margin-left: 20px;
      width: 24px;
      height: 24px;
    }
  }
}

.more_n {
  width: 180px;
  height: 40px;
  background: #F7F7F7;
  border-radius: 20px;
  margin: 10px auto;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #393F47;

  img {
    width: 9px;
    height: 10px;
    margin-left: 6px;
  }
}

.usercon_right {
  .right_i {
    width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px #E8EDF3;
    border-radius: 6px;
    padding: 30px;
    margin-bottom: 30px;

    .plan_i {
      width: 240px;
      background: #FFFFFF;
      border: 1px solid #EBEBEB;
      border-radius: 2px;
      padding: 20px 15px;
      margin-bottom: 20px;

      .plan_bottom {
        border-top: 1px solid #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;

        .plan_dec {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .plan_btn {
          width: 70px;
          height: 32px;
          background: #F7F7F7;
          border: 1px solid #EBEBEB;
          border-radius: 16px;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .plan_name {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #393F47;
      }

      .plan_is {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;

        .plan_time {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Arial;
          font-weight: 400;
          color: #666666;

          img {
            width: 16px;
            height: 16px;
            margin-right: 7px;
          }
        }

        .plan_status {
          // width: 55px;
          padding: 0 4px;
          height: 20px;
          background: #F6FAFF;
          border-radius: 10px;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #308DFF;

          img {
            width: 16px;
            height: 16px;
            margin-right: 7px;
          }
        }
      }
    }
  }
}

.quenflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.quenflex_input {
  width: 200px;
  height: 40px;
  background: #F7F7F7;
  border: 1px solid #EBEBEB;
  border-radius: 20px;
  display: flex;
  align-items: center;

  input {
    height: 38px;
    border: none;
    background: #F7F7F7;
    width: 100px;
  }

  img {
    width: 17px;
    height: 17px;
    margin: 0 10px 0 20px;
  }
}

.collapse_title {
  display: flex;
  align-items: center;

  .coll_name {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393F47;
    padding-left: 10px;
  }

  .coll_dec {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 10px;
    display: flex;
    align-items: center;

    span {
      color: #308DFF;
      font-weight: bold;
      display: block;
      margin: 0 4px;
    }
  }
}

.elcoll {
  border-left: 1px solid #EBEBEB;
  border-right: 1px solid #EBEBEB;
  margin-bottom: 20px;
}

/deep/.el-collapse-item__header {
  background: #FAFAFA;
  height: 60px;
}

.coll_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;

  .coll_i {
    width: 360px;
    height: 171px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    padding-top: 24px;
    position: relative;
    margin-bottom: 30px;

    .coll_btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 360px;
      height: 50px;
      background: #FAFAFA;
      border-radius: 6px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393F47;
    }

    .coll_name {
      width: 324px;
      height: 41px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
      margin: 0 auto 0 10px;
    }

    .coll_dec {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: 10px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 18px;

      span {
        color: #308DFF;
        font-weight: bold;
        display: block;
        margin: 0 4px;
      }
    }
  }
}

.ributton {
  width: 240px;
  height: 40px;
  background: #F7F7F7;
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #393F47;
}

.ributton:active {
  opacity: .7;
}

.question_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #F7F7F7;

  .question_left {
    display: flex;
    align-items: center;

    div {
      width: 40px;
      height: 40px;
      background: #F5F4F4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393F47;
      margin-right: 10px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .question_num {
    font-size: 18px;
    font-family: DIN Next LT Pro;
    font-weight: bold;
    color: #FF564C;
  }
}

.colc_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.colc_i {
  width: 320px;
  height: 110px;
  background: #FAFAFB;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 46px 30px 0 30px;
  position: relative;

  img {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    margin-top: 4px;
  }

  .colc_i_pos {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 34px;
    background: #308dff1c;
    border-radius: 6px 0px 6px 0px;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #308DFF;
  }

  .colc_i_name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
}

.tpelist_i:nth-child(even) {
  background-color: #ffffff;
}

.tpelist_i {
  width: 810px;
  height: 80px;
  margin: 0 auto;
  background: #fbfbfb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  .tpelist_i_left {
    display: flex;
    align-items: center;

    .tpe_inx {
      font-size: 40px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #308dff;
    }

    .tpe_type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 24px;
      background: #308dff0a;
      border-radius: 3px;
      margin: 0 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #308dff;

      img {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }
    }

    .tpe_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
  }

  .tpelist_i_right {
    display: flex;
    align-items: center;

    .tpe_pro {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;

      span {
        font-size: 18px;
        font-family: DIN Next LT Pro;
        font-weight: bold;
        color: #308dff;
      }
    }

    .tpe_btn {
      margin-left: 20px;
      width: 100px;
      height: 32px;
      background: #308dff;
      border-radius: 16px;
      line-height: 30px;
      text-align: center;
      color: #ffffff;
    }
  }
}

.guqisty {
  background: #e4e0e0 !important;
  color: rgb(153, 153, 153) !important;
}
</style>