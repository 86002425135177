<template>
  <div class="uinfo" ref="ioggg">
    <div class="uinfo_left">
      <div class="left_i">
        <template>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="'我的收藏(' + (ctatiInfo.collect_count || '') + ')'"
              name="first"
            >
              <div class="writing_list">
                <div class="nothing" v-if="collectList == ''">
                  <img src="@/assets/images/nothing.png" alt="" />
                </div>
                <div
                  class="writing_i"
                  v-else
                  v-for="(cell, index1) in collectList"
                  :key="index1"
                  @click="goAns(cell)"
                >
                  <div class="writing_i_left">
                    <img src="@/assets/images/login/icon_tb_b.png" alt="" />
                    {{ cell.content_info }}
                  </div>
                  <div class="writing_i_right">{{ cell.counts }}</div>
                </div>
                <div class="pagedv">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="collectCount"
                    @current-change="getMyCollect"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="我的资料" name="second">
              <div style="width: 800px">
                <el-radio-group
                  @tab-click="tableClick"
                  v-model="tabPosition"
                  style="margin-bottom: 30px"
                >
                  <el-radio-button label="1">特训</el-radio-button>
                  <el-radio-button label="2">专题</el-radio-button>
                </el-radio-group>
                <div class="nothing" v-if="formaList == ''">
                  <img src="@/assets/images/nothing.png" alt="" />
                </div>
                <div
                  class="cour_i"
                  v-else
                  v-for="(fora, index2) in formaList"
                  :key="index2"
                >
                  <div class="cour_info">
                    <div class="cour_details">
                      <div class="cour_name">{{ fora.name }}</div>
                      <div class="cour_kankj">
                        <div>
                          <img
                            src="../../assets/images/login/icon_zt_zip.png"
                            alt=""
                            style="margin-right: 8px"
                          />PDF电子资料
                        </div>
                        <div style="width: 180px">
                          <!-- 共<span :style="{ color: backgChange(3) }">1</span> 份 -->
                        </div>
                      </div>
                    </div>
                    <div class="cour_btn" @click="forDoen(fora.file_url)">
                      查看
                    </div>
                  </div>
                </div>
                <div class="pagedv">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="formaCount"
                    @current-change="getMyInformation"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              :label="'错题巩固(' + (ctatiInfo.error_num || '') + ')'"
              name="third"
            >
              <el-table :data="wrongList" stripe style="width: 100%">
                <el-table-column prop="content_name" label="课程名称">
                </el-table-column>
                <el-table-column
                  prop="type"
                  label="类型"
                  width="80"
                  align="center"
                >
                  <template slot="header" slot-scope="scope">
                    <el-dropdown @command="commandDro">
                      <span class="el-dropdown-link">
                        {{ commanValue == 1 ? "特训" : "专题"
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">特训</el-dropdown-item>
                        <el-dropdown-item command="2">专题</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template slot-scope="scope">
                    {{ scope.row.type == 2 ? "测验" : "练习" }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="exam_start_time"
                  label="巩固进度"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="prol">
                      <div class="cour_progress">
                        <div
                          class="courpro_lg"
                          :style="{
                            width:
                              (scope.row.recall_counts / scope.row.counts) *
                                100 +
                              '%',
                          }"
                        ></div>
                        <div class="cour_gard">
                          <div v-for="(item, index) in 4" :key="index"></div>
                        </div>
                      </div>
                      <div class="pnumv">
                        {{ scope.row.recall_counts }}/<span>{{
                          scope.row.counts
                        }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="操作"
                  width="150px"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="gubuttion" @click="goWorngd(scope.row)">
                      立即巩固
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="pagedv" style="margin: 20px auto">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="wrongCount"
                  @current-change="getWrongQuestionRecall"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="我的笔记"
              name="notes"
            >
            <div class="nothing" v-if="notelist == ''">
                  <img src="@/assets/images/nothing.png" alt="" />
                </div>
              <div class="notlist" v-for="(item, index1) in notelist"
                      :key="index1">
                <div class="notlist_title" v-html="item.ques_info.title"></div>
                <div class="slid_bpinl">
                  <div class="pplist">
                    <div
                      class="pplist_i"
                      v-for="(itt, index) in item.contents"
                      :key="index"
                    >
                      <img :src="userInfos.avatar" alt="" />
                      <div class="pp_details">
                        <div class="pp_nxis">
                          <div class="pnx_name">
                            <div class="name">{{ userInfos.name }}</div>
                            <div class="time"></div>
                            {{ itt.create_time}}
                          </div>
                          <div class="xiu_btn">
                            <div class="gai_btn" @click="open(itt,item)">修改</div>
                            <div @click="getNDel(itt.id)">删除</div>
                          </div>
                        </div>
                        <div class="shiyedx">{{ itt.content }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="notCount"
                  :page-size="5"
                  @current-change="getNotesList"
                >
                </el-pagination>
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
    <div class="uinfo_right">
      <div class="right_i" style="margin-top: 0">
        <div class="question_set" style="margin-bottom: 20px">题本</div>
        <div
          class="quenflex_input"
          style="margin: 0 auto 30px auto"
          @click="inputClick"
        >
          <img src="../../assets/search.png" alt="" />
          <!-- <input @click="inputClick" type="text" disabled placeholder="搜题"> -->
          <div style="color: #999">搜题</div>
        </div>
        <div class="question_i" @click="activeName = 'third'">
          <div class="question_left">
            <div>
              <img src="../../assets/images/login/icon_tb_a.png" alt="" />
            </div>
            错题
          </div>
          <div class="question_num">{{ ctatiInfo.error_num }}</div>
        </div>
        <div class="question_i" @click="activeName = 'notes'">
            <div class="question_left">
                <div><img src="../../assets/images/login/icon_tb_b.png" alt=""></div>
              笔记
            </div>
            <div class="question_num" style="color:#308DFF;">{{ctatiInfo.note_count}}</div>
          </div>
        <div class="question_i" @click="activeName = 'first'">
          <div class="question_left">
            <div>
              <img src="../../assets/images/login/icon_tb_c.png" alt="" />
            </div>
            收藏
          </div>
          <div class="question_num" style="color: #f7a243">
            {{ ctatiInfo.collect_count }}
          </div>
        </div>
        <div class="question_i" @click="activeName='second'">
                <div class="question_left">
                   <div><img style="width:22px;height:22px" src="../../assets/images/login/woelss.png" alt=""></div>
                  资料
                </div>
                <div class="question_num" style="color:#ff3069;">{{ctatiInfo.Information_num}}</div>
              </div>
      </div>
    </div>
    <!--  -->
  </div>
</template>
  
  <script>
// (function() {var _53code = document.createElement("script");_53code.src = "https://tb.53kf.com/code/code/043988c5790032a0f9caef5a4257fd113/3";var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(_53code, s);})();
import {
  getSubject,
  getSubjectStatistics,
  getMyCollect,
  getMyInformation,
  getWrongQuestionRecall,
  getStuCollectQues,
  getNotesList,
  delNotes,
  addNotes
} from "@/utils/erpapi.js";
import { mapState } from "vuex"; // 导入mapState辅助函数
import { EventBus } from "@/eventBus/eventBus";
export default {
  data() {
    return {
      subjData: [],
      kanzkShow: true,
      inputject: "",
      userName: "",
      activeName: "first",
      ctatiInfo: {}, //题本统计
      userinfod: {},
      imageUrl: "",
      collectCount: 0,
      collectList: [],
      wrongList: [],
      wrongCount: 0,
      formaList: [],
      formaCount: 0,
      commanValue: 1,
      tabIndex: 0,
      tabShow: true,
      tabPosition: 1,
      notelist: [],
      notCount:0,
      userInfos: JSON.parse(localStorage.getItem('userInfo')),
    };
  },
  computed: {
    ...mapState(["pathName"]), // 将指定的状态名称添加到计算属性中
  },
  watch: {
    tabPosition(val) {
      this.getMyInformation();
    },
  },
  components: {},
  created() {
    this.activeName = this.$route.query.name;
    this.getSubject();
    this.getSubjectStatistics();
    this.getMyCollect();
    this.getMyInformation();
    this.getWrongQuestionRecall();
    this.getNotesList();
  },
  methods: {
    // 笔记列表
    getNotesList(val) {
      getNotesList({
        page: val||1,
        limit: 5,
      }).then((res) => {
        this.notCount=res.count
        this.notelist = res.data;
      });
    },
    open(art,data) {
      console.log(art)
			this.$prompt('请输入笔记', '', {
				inputType: 'textarea',
				inputValue: art.content,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputValidator: value => {
					if (value.length > 0) {
						return true;
					}
					return !(value.length < 1000);
				},
			}).then(({ value }) => {
				  addNotes({ 
            id:art.id,
					checkpoint_id: data.id>1?'':data.checkpoint_id,
          content_id: data.content_id>1?data.content_id:'',
					type: data.type,
					ques_list_id: data.ques_list_id,
					content: value,
					source: data.source,
					topic_id: data.topic_id
				 }).then(res => {
					this.$message({
						message: '已修改',
						type: 'success'
					});
					this.getNotesList()
				}).catch(err => {

				})
        
			}).catch(({ value }) => {

			});
		},
    		// 删除学习笔记
		getNDel(id) {
			this.$confirm('您确认要删除该条笔记吗？', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delNotes({ id: id }).then(res => {
					this.$message({
						message: '已删除',
						type: 'success'
					});
					this.getNotesList()
				}).catch(err => {
				})
			}).catch(() => {

			});

		},
    // 错题巩固
    goWorngd(data) {
      console.log(data);
      this.$router.push(
        "/erroranswer?id=" +
          (data.content_id||data.checkpoint_id) +
          "&type=" +
          data.type +
          "&source=" +
          this.commanValue +
          "&name=" +
          data.content_name
      );
    },
    //
    goAns(data) {
      getStuCollectQues({
        checkpoint_id: data.content_id>1?'':data.checkpoint_id,
        content_id: data.content_id>1?data.content_id:'',
        source: data.source,
        limit: 20,
      }).then((res) => {
        let sussArr = [];
        res.data.forEach((item, index) => {
          item.ques_list.is_right = item.is_right;
          item.ques_list.user_answer = item.user_answer;
          if(item.ques_list.text){
                item.ques_list.text=item.ques_list.text.replace(/<img/gi,'<img style="max-width:800px;height:auto;"')
                item.ques_list.text=item.ques_list.text.replace(/<p/gi,'<p style="font-size:16px;color:#333333;letter-spacing: 1px;line-height: 24px;"')
              }
              item.ques_list.title=item.ques_list.title.replace(/<p/gi,'<p style="font-size:16px;color:#333333;letter-spacing: 1px;line-height: 24px;"')
              item.ques_list.title=item.ques_list.title.replace(/<img/gi,'<img style="max-width:800px;height:auto;margin:6px 0"')
          sussArr.push(item.ques_list);
        });
        localStorage.setItem("analysis", JSON.stringify(sussArr));
        let navarrs = {
          arr: [],
          title: "",
          kindex: 0,
        };
        const routeData = this.$router.resolve({
          path: "/cellAns?parameter",
          query: {
            parameter: encodeURIComponent(
              encodeURIComponent(JSON.stringify(navarrs))
            ),
            kindex: 0,
            checkpoint_id: data.content_id>1?'':data.checkpoint_id,
            content_id: data.content_id>1?data.content_id:'',
            subjectType: data.source,
            type: data.type,
          },
        });
        window.open(routeData.href, "_blank");
      });
      return;
      if (data.source == 1) {
        this.$router.push(
          "/answer?id=" + (data.content_id||data.checkpoint_id) + "&type=" + data.type
        );
      } else {
        this.$router.push(
          "/Cetopanswer?id=" + (data.content_id||data.checkpoint_id) + "&type=" + data.type
        );
      }
    },
    commandDro(e) {
      this.commanValue = e;
      this.getWrongQuestionRecall();
    },
    // 错题巩固
    getWrongQuestionRecall(val) {
      getWrongQuestionRecall({
        page: val || 1,
        limit: 10,
        source: this.commanValue,
      }).then((res) => {
        this.wrongList = res.data.list;
        this.wrongCount = res.data.count;
      });
    },
    // 我的收藏
    getMyCollect(val) {
      getMyCollect({
        page: val || 1,
        limit: 10,
      }).then((res) => {
        this.collectCount = res.data.count;
        this.collectList = res.data.list;
      });
    },
    // 我的资料
    getMyInformation(val) {
      getMyInformation({
        page: val || 1,
        limit: 10,
        source: this.tabPosition,
      }).then((res) => {
        this.formaList = res.data.list;
        this.formaCount = res.data.count;
      });
    },
    tableClick() {
      console.log("888");
      this.getMyInformation();
    },
    // 下载
    forDoen(url) {
      window.open(url);
    },
    // 题本统计
    getSubjectStatistics() {
      getSubjectStatistics().then((res) => {
        this.ctatiInfo = res.data;
      });
    },
    handleClick(tab, event) {
      console.log(tab.index);
      console.log(this.tabIndex);
      if (tab.index == 1 && this.tabIndex == tab.index) {
        this.tabShow = !this.tabShow;
        this.getMyInformation();
      }
      this.tabIndex = tab.index;
    },
    // 题搜索
    inputClick() {
      this.$router.push("/courseSearch");
    },
    // 专题搜索
    blurSeacrh() {
      this.getSubject();
    },
    // 专题详情
    goTopicinfo(data) {
      this.$router.push("/topicinfo?id=" + data.id);
    },
    // 专题
    getSubject() {
      getSubject({
        name: this.inputject,
      }).then((res) => {
        this.subjData = res.data;
      });
    },
  },
};
</script>
  
  <style scoped="scoped" lang="scss">
/deep/.el-dropdown-link {
  color: #ffffff;
}
/deep/.avatar-uploader {
  width: 180px;
  margin: 0 auto;
}
.right_i {
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  padding: 30px;
  margin-top: 30px;
}
.question_i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #f7f7f7;
  .question_left {
    display: flex;
    align-items: center;
    div {
      width: 40px;
      height: 40px;
      background: #f5f4f4;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393f47;
      margin-right: 10px;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .question_num {
    font-size: 18px;
    font-family: DIN Next LT Pro;
    font-weight: bold;
    color: #ff564c;
  }
}
.prol {
  display: flex;
  align-items: center;
  .pnumv {
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    span {
      font-size: 18px;
      font-family: DIN Next LT Pro;
      font-weight: bold;
      color: #308dff;
    }
  }
}
.cour_progress {
  position: relative;
  width: 100px;
  height: 10px;
  margin-left: 35px;
  margin-right: 15px;
  background: #d6e8ff;
  border-radius: 2px;
  .courpro_lg {
    position: absolute;
    height: 10px;
    z-index: 90;
    width: 50%;
    background: #308dff;
    top: 0;
    left: 0;
    border-radius: 2px;
  }
  .cour_gard {
    position: absolute;
    z-index: 99;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    div {
      width: 20px;
      height: 10px;
      background: #e4050500;
      border-right: 4px solid #ffffff;
      margin: 0;
    }
  }
}
.gubuttion {
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 16px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 32px;
  margin: 0 auto;
}
/deep/.el-table thead tr > th {
  height: 50px;
  background-color: #308dff;
  color: #ffffff;
}
/deep/.el-table__header-wrapper {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
// 更改表格每行背景颜色
/deep/.el-table tbody tr > td {
  height: 75px;
}
/deep/.el-table td {
  border-bottom: none;
}
/deep/.el-table__body-wrapper {
  border-left: 1px solid #e4e8f2;
  border-right: 1px solid #e4e8f2;
  border-top: none;
}
.pagedv {
  width: 870px;
  margin: 10px auto;
  text-align: center;
}
.writing_list {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .writing_i {
    width: 390px;
    height: 70px;
    background: #f8f9fb;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 30px;
    .writing_i_left {
      display: flex;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
        margin-right: 15px;
      }
    }
    .writing_i_right {
      width: 40px;
      height: 22px;
      background: #308dff;
      border-radius: 11px;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      text-align: center;
    }
  }
}
//
.uinfo {
  width: 1200px;
  margin: 0 auto 30px auto;
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  .uinfo_left {
    width: 870px;
  }
  .uinfo_right {
    width: 300px;
  }
}
.user_title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
}
.stu_jiy {
  margin-top: 14px;
  width: 246px;
  height: 116px;
  background: #fafafa;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  padding: 20px;
  .stu_jiy_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
  }
  .stu_info {
    margin-top: 10px;
    display: flex;
    img {
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }
    div {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393f47;
    }
  }
}
//
.course_list {
  overflow: hidden;
  .course_i {
    width: 810px;
    height: 110px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .cour_info {
      width: 690px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cour_btn {
        width: 100px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 32px;
      }
      .cour_details {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cour_name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #393f47;
        }
        .cour_kankj {
          display: flex;
          align-items: center;
          .cour_progress {
            position: relative;
            width: 100px;
            height: 10px;
            margin-left: 35px;
            margin-right: 15px;
            background: #d6e8ff;
            border-radius: 2px;
            .courpro_lg {
              position: absolute;
              height: 10px;
              z-index: 90;
              width: 50%;
              background: #308dff;
              top: 0;
              left: 0;
              border-radius: 2px;
            }
            .cour_gard {
              position: absolute;
              z-index: 99;
              width: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              div {
                width: 20px;
                height: 10px;
                background: #e4050500;
                border-right: 4px solid #ffffff;
                margin: 0;
              }
            }
          }
          div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 8px;
            span {
              font-size: 18px;
              color: #308dff;
              font-weight: bold;
            }
          }
        }
      }
    }
    .cour_img {
      width: 120px;
      height: 110px;
      background: linear-gradient(90deg, #0fa2ff, #17bdff);
      border-radius: 6px 0px 0px 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 30px;
        height: 30px;
      }
      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}
.question_set {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #393f47;
  display: flex;
  align-items: baseline;
}
.left_i {
  width: 870px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px #e8edf3;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px;
  box-sizing: border-box;
}
.more_n {
  width: 180px;
  height: 40px;
  background: #f7f7f7;
  border-radius: 20px;
  margin: 10px auto;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #393f47;
  img {
    width: 9px;
    height: 10px;
    margin-left: 6px;
  }
}
.collapse_title {
  display: flex;
  align-items: center;
  .coll_name {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #393f47;
    padding-left: 10px;
  }
  .coll_dec {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 10px;
    display: flex;
    align-items: center;
    span {
      color: #308dff;
      font-weight: bold;
      display: block;
      margin: 0 4px;
    }
  }
}
.elcoll {
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  margin-bottom: 20px;
}
/deep/.el-collapse-item__header {
  background: #fafafa;
  height: 60px;
}
.quenflex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.quenflex_input {
  width: 200px;
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  display: flex;
  align-items: center;
  input {
    height: 38px;
    border: none;
    background: #f7f7f7;
    width: 100px;
  }
  img {
    width: 17px;
    height: 17px;
    margin: 0 10px 0 20px;
  }
}
.coll_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
  .coll_i {
    width: 360px;
    height: 171px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    padding-top: 24px;
    position: relative;
    margin-bottom: 30px;
    .coll_btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 360px;
      height: 50px;
      background: #fafafa;
      border-radius: 6px;
      line-height: 50px;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #393f47;
    }
    .coll_name {
      width: 324px;
      height: 41px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 26px;
      margin: 0 auto 0 10px;
    }
    .coll_dec {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-left: 10px;
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 18px;
      span {
        color: #308dff;
        font-weight: bold;
        display: block;
        margin: 0 4px;
      }
    }
  }
}
.cour_i {
  width: 810px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 20px 0;
  margin-bottom: 20px;
}
.cour_info {
  width: 810px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .cour_btn {
    width: 100px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 16px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 32px;
  }
  .cour_details {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .cour_name {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #393f47;
    }
    .cour_kankj {
      display: flex;
      align-items: baseline;
      .cour_progress {
        position: relative;
        width: 100px;
        height: 10px;
        margin-left: 35px;
        margin-right: 15px;
        background: #d6e8ff;
        border-radius: 2px;
        .courpro_lg {
          position: absolute;
          height: 10px;
          z-index: 90;
          width: 50%;
          background: #308dff;
          top: 0;
          left: 0;
          border-radius: 2px;
        }
        .cour_gard {
          position: absolute;
          z-index: 99;
          width: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          div {
            width: 20px;
            height: 10px;
            background: #e4050500;
            border-right: 4px solid #ffffff;
            margin: 0;
          }
        }
      }
      div {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-right: 8px;
        span {
          font-size: 18px;
          color: #308dff;
          font-weight: bold;
        }
      }
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-icon-plus {
  border: 1px dashed #999;
}
.nothing {
  width: 100%;
  margin: 20px auto;
  img {
    width: 250px;
  }
}

//
.pplist {
  width: 852px;
  border-top: 1px solid #e9e9e9d2;
  padding-top: 30px;
}

.pplist_i {
  display: flex;
  padding-bottom: 20px;
}

.pplist_i img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 20px;
}

.pp_details {
  width: 750px;
}

.pp_nxis {
  margin-bottom: 27px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.pnx_name {
  display: flex;
  align-items: center;
}

.pnx_name .name {
  margin-right: 14px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.pnx_name .time {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #999999;
}

.xiu_btn {
  display: flex;
  align-items: center;
}

.xiu_btn div {
  width: 46px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: #eeeeee;
  border-radius: 3px;
  margin-left: 18px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.gai_btn {
  color: #5694f7 !important;
  background: #5694f738 !important;
  cursor: pointer;
}

.xiu_btn div:hover {
  box-shadow: 0px 0px 6px 0px #5a595991;
  scale: 1.05;
}

.shiyedx {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}
.notlist{
  margin-bottom: 30px;
}
.notlist_title{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  padding: 20px;
  border: 1px solid #f1efef;
}
</style>